<template>
    <div class="no-overflow">
        <div v-if="otherUser != null">
            <AppbarFixed :title="otherUser.username" :hasBackButton="true"/>
            <ProfileHeader :parsillUser="otherUser" :projectCount="projects.length" :isPublic="true" :saveCount="saved()"/>
            <OtherProfileContent :parsillUser="parsillUser" :otherUser="otherUser" :projects="projects"/>
        </div>
        <div v-else>
            <b-spinner class="mt-5"></b-spinner>
        </div>
        <div class="bottom-margin-box"></div>
        <BottomNavBar :currentIndex="2" @updateIndex="updateIndex"/>
    </div>
</template>

<script>
import AppbarFixed from '../../components/general/AppbarFixed.vue';
import ProfileHeader from '../../components/profile/ProfileHeader.vue';
import OtherProfileContent from '../../components/other_profile/OtherProfileContent.vue';
import BottomNavBar from '../../components/general/BottomNavBar.vue';

import { db } from '../../db';

import { mapState } from 'vuex';

export default {
    components: {
        AppbarFixed,
        ProfileHeader,
        OtherProfileContent,
        BottomNavBar,
    },
    computed: {
        ...mapState({
            parsillUser: (state) => state.parsillUser,
        }),
        otherUserId: function() {
            return this.$route.params.id;
        },
    },
    methods: {
        loadOtherUser(id) {
            console.log("loading other user");
            db.collection("users").doc(id).get()
            .then((snapshot) => {
                var data = snapshot.data();
                data.id = snapshot.id;
                this.otherUser = data;
                console.log(data);
                console.log(this.otherUser.username);
            })
            .catch((err) => {
                alert(err);
            })
        },
        loadUserProjects(id) {
            db.collection("projects").where("creatorId", "==", id).get()
            .then((snapshot) => {
                var loadedProjects = [];
                for (var i=0; i < snapshot.docs.length; i++) {
                    var doc = snapshot.docs[i];
                    var data = doc.data();
                    data.id = doc.id;
                    loadedProjects.push(data);
                }
                this.projects = loadedProjects;
            })
            .catch((err) => {
                alert(err);
            })
        },
        saved: function() {
            var saved = 0;
            for (var i=0; i < this.projects.length; i++) {
                var proj = this.projects[i];
                if (proj.saves != null) {
                    saved += proj.saves;
                }
            }
            return saved;
        },
        updateIndex(index) {
            if (index == 4) {
                // means its create project
                this.$router.push("/create/project");
                index = 1;
            } else {
                this.$router.push("/");
            }
            this.$store.commit("updateTabIndex", index);
        }
    },
    data() {
        return {
            projects: [],
            otherUser: null,
        }
    },
    beforeMount() {
        this.loadOtherUser(this.otherUserId);
        this.loadUserProjects(this.otherUserId);
    }
}
</script>

<style scoped>
    
</style>