<template>
    <div>
        <!-- <div v-for="(row, index) in projectsChunked" :key="index" class="d-flex flex-row justify-content-around mt-4">
            <MyProjectCard v-if="projects.length > 0" :project="row[0]" @viewMyProject="viewMyProject"/>
            <MyProjectCard v-if="row.length > 1" :project="row[1]" @viewMyProject="viewMyProject"/>
            <div v-else class="project-card-dim">
            </div>
        </div> -->
        <div class="projects-list mx-2 my-2">
            <home-project-card v-for="project in sortedProjects" :key="project.id" 
            :project="project" 
            :category="categoryFromProject(project)" 
            @goToProject="viewMyProject"
        />
        </div>
        <div v-if="projects.length == 0" class="mt-5">
            <h3>This user has no projects yet.</h3>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
// import NewProjectCard from '../../components/profile/nested/NewProjectCard.vue';
// import MyProjectCard from '../../components/profile/nested/MyProjectCard.vue';
import HomeProjectCard from '../home/HomeProjectCard.vue';
export default {
    components: {
        // MyProjectCard,
        HomeProjectCard,
    },
    props: {
        parsillUser: null,
        otherUser: null,
        projects: {type: Array, default: () => []},
    },
    computed: {
        // projectsChunked: function() {
        //     if (this.projects.length == 0) return [];

        //     var chunks = [];

        //     for (var i=0; i < this.sortedProjects.length; i++) {
        //         if (i % 2 == 0) {
        //             // add new chunk
        //             chunks.push([]);
        //         }

        //         // add project to latest chunk
        //         chunks[chunks.length-1].push(this.sortedProjects[i]);
        //     }

        //     return chunks;
        // },
        sortedProjects: function() {
            if (this.projects.length == 0) return [];

            var sortedProjects = [...this.projects];

            if (this.otherUser.projectOrder) {
                console.log(this.otherUser.projectOrder);
                // means we have a set order for the projects, so sort
                sortedProjects.sort((a, b) => {
                    var aIndex = this.otherUser.projectOrder.indexOf(a.id);
                    var bIndex = this.otherUser.projectOrder.indexOf(b.id);
                    
                    if (aIndex == -1) aIndex = 1000;
                    if (bIndex == -1) bIndex = 1000;

                    return aIndex - bIndex;
                });
            }

            console.log(sortedProjects.map(e => e.id));

            return sortedProjects;
        },
        ...mapState({
            categories: (state) => state.categories,
        })
    },
    methods: {
        viewMyProject(project) {
           this.$router.push('/project/' + project.id);
        },
        categoryFromProject(project) {
            return this.categories.find((e) => e.id == project.categoryId);
        }
    }
}
</script>

<style scoped>

</style>